/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import "@fontsource/raleway/100.css"
import "@fontsource/raleway/200.css"
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/400.css"
import "@fontsource/raleway/500.css"
import "@fontsource/raleway/600.css"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/800.css"
import "@fontsource/raleway/900.css"

// Markdown formatting, uses Tailwind @apply primitive to apply Tailwind's utility classes to
// elements created by the Markdown parser
import "./src/styles/markdown.css"

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    // Array of callbacks to be executed when consent changes
    window.consentListeners = []

    /**
     *   Called from GTM template to set callback to be executed when user consent is provided.
     *   @param {function} Callback to execute on user consent
     */
    window.addConsentListener = callback => {
      window.consentListeners.push(callback)
    }
  }
}
